/**
 * Fully expanded "now" indicator line
 * @see https://github.com/fullcalendar/fullcalendar/issues/4609#issuecomment-1010706978
 */

:root {
  .fc-timeGridWeek-view {
    .fc-timegrid-now-indicator-container {
      overflow: visible !important;
    }

    .fc-timegrid-now-indicator-line {
      width: calc(700% + 6px) !important;
      transform: translateX(calc(-1 * var(--fc-now-indicator-shift)));

      &::before {
        position: absolute;
        left: calc(var(--fc-now-indicator-shift) - 7px);
        display: block;
        width: 13px;
        height: 13px;
        margin-top: -7px;
        background-color: var(--red-400);
        border: 1px solid var(--reactist-bg-default);
        border-radius: 13px;
        content: '';
      }
    }

    .fc-day {
      &:nth-child(2) .fc-timegrid-now-indicator-line {
        --fc-now-indicator-shift: 0%;
      }

      &:nth-child(3) .fc-timegrid-now-indicator-line {
        --fc-now-indicator-shift: 14.29%;
      }

      &:nth-child(4) .fc-timegrid-now-indicator-line {
        --fc-now-indicator-shift: 28.57%;
      }

      &:nth-child(5) .fc-timegrid-now-indicator-line {
        --fc-now-indicator-shift: 42.86%;
      }

      &:nth-child(6) .fc-timegrid-now-indicator-line {
        --fc-now-indicator-shift: 57.14%;
      }

      &:nth-child(7) .fc-timegrid-now-indicator-line {
        --fc-now-indicator-shift: 71.43%;
      }

      &:nth-child(8) .fc-timegrid-now-indicator-line {
        --fc-now-indicator-shift: 85.71%;
      }
    }
  }
}
