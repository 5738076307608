:root {
  --white-high-emphasis: #fff;
  --white-medium-emphasis: #ffffffbf;
  --white-disabled: #ffffff50;
  --white-inactive: #ffffff80;
  --white-overlay: #ffffff0a;
  --white: var(--white-high-emphasis);
  --gray-black: #092540;
  --gray-darkest: #687a8d;
  --gray-dark: #c6cad8;
  --gray-inactive: #c4cbd1;
  --gray-light: #d8dee7;
  --gray-lighter: #e5eaf0;
  --gray-lightest: #f7f8fc;
  --gray-divider: #f0f2fa;
  --gray-chip: #f0f4f7;
  --black-high-emphasis: #092540de;
  --black-medium-emphasis: #09254099;
  --black-disabled: #a3acb5;
  --black-inactive: #0925408a;
  --black-shadow: #393e4b26;
  --background-dark: #d0dce3;
  --background-light: #fafdff;
  --primary-900: #175499;
  --primary-800: #1b74bb;
  --primary-700: #1e85d0;
  --primary-variant: var(--primary-700);
  --primary-600: #2098e4;
  --primary-500: #21a7f3;
  --primary-accent: var(--primary-500);
  --primary-400: #34b3f6;
  --primary-300: #53c1f7;
  --primary-200: #83d2fa;
  --primary-100: #b4e4fc;
  --primary-50: #e1f5fe;
  --primary-lightest: #f2f9fe;
  --primary-accent-background: #21a7f326;
  --secondary-dark: #d49fe6;
  --secondary-light: #f5ddf5;
  --secondary-lightest: #fbf5ff;
  --error-darkest: #c62828;
  --error-dark: #e53935;
  --error-light: #ffd6d6;
  --error-lighter: #fce7e7;
  --error-lightest: #fef4f4;
  --warning-darkest: #ffa000;
  --warning-light: #ffe9c7;
  --warning-lighter: #fff5e5;
  --warning-lightest: #fffaf3;
  --success-darkest: #00c853;
  --success-dark: #00e676;
  --success-light: #d7f8e7;
  --success-lighter: #ecf9f3;
  --success-lightest: #f6fcf9;
  --bg-dark: var(--gray-lighter);
  --bg-light: var(--gray-lightest);
  --stripe-button: #635bff;
  --focus-outline: var(--primary-700);

  /* components */
  --form-content-item-icon: var(--primary-500);
}

.theme-upvio {
  --gray-black: #092540;
  --gray-darkest: #2e2e2e;
  --gray-dark: #c2c2c2;
  --gray-inactive: #cbcbcb;
  --gray-light: #d8dee7;
  --gray-lighter: #e5eaf0;
  --gray-lightest: #f7f8fc;
  --gray-divider: #e5e5e5;
  --gray-chip: #e0e0e0;
  --black-high-emphasis: #212121;
  --black-medium-emphasis: #09254099;
  --black-disabled: #9e9e9e;
  --black-inactive: #757575;
  --black-shadow: #393e4b26;
  --background-dark: #e8e8e8;
  --background-light: #fafafa;
  --primary-900: #290064;
  --primary-800: #380582;
  --primary-700: #450b99;
  --primary-variant: var(--primary-700);
  --primary-600: #5110af;
  --primary-500: #5f20ba;
  --primary-accent: var(--primary-500);
  --primary-400: #7838d5;
  --primary-300: #ad75ff;
  --primary-200: #bf9df2;
  --primary-100: #d5c3ff;
  --primary-50: #ece3ff;
  --primary-lightest: #f7f3ff;
  --primary-accent-background: #290064;
  --secondary-dark: #26ded2;
  --secondary-light: #aff5f0;
  --secondary-lightest: #dffaf8;

  /* components */
  --form-content-item-icon: var(--white);
}
