/**
 * Hide Stoplight branding on developer portal.
 * Their logo keeps rendering at weird sizes after nextjs route change.
 */
a[href^="https://stoplight.io"]
{
  display: none !important;
}

.RaLayout-appFrame {
  margin-top: 0 !important;
  padding-top: 77px;
}

/**
 * Hide the UserGuiding checklist button
 */
[data-testid='checklist-text-launcher'] {
  display: none !important;
}

#userguiding-launcher {
  // display: none !important;
  // left: -9999px;
  top: -9999px !important;
  bottom: 0 !important;
}

// stoplight docs icon
.sl-icon {
  max-width: 16px;
}
