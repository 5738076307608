@import 'australis/dist/esm/foundation/base';

:root {
  // Global styles

  .fc-non-business {
    background: var(--gray-light-050);
    background-image: radial-gradient(
      circle at 1px 1px,
      var(--gray-light-100) 1px,
      transparent 0
    );
    background-size: 5px 5px;
  }

  .fc-scrollgrid {
    border: none;
  }

  .fc-col-header th,
  .fc-scrollgrid th {
    border: none;
  }

  // Header cell
  .fc-col-header-cell {
    @include body-sm-regular;

    font-weight: 500;

    > div {
      margin-left: -0.5px;
      padding: 10px;
      border: 1px solid var(--gray-light-100);
      border-right: none;
    }

    &:last-child {
      > div {
        border-right: 1px solid var(--gray-light-100);
        border-top-right-radius: 10px;
      }
    }
  }

  .fc-daygrid-day-number {
    color: var(--gray-light-900);
  }

  // Day view specific styles
  .fc-timeGridDay-view {
    .fc-day-week-view-label {
      display: none;
    }
  }

  // styles for calendar week view header
  .fc-timeGridWeek-view {
    .fc-scrollgrid-sync-inner {
      .fc-col-header-cell-cushion {
        .fc-day-week-view-label {
          display: flex;
          flex-direction: column;
          align-items: center;
          color: var(--gray-light-900);
          font-size: 0.75rem;
          text-transform: uppercase;

          & > span {
            font-size: 0.875rem;
            font-weight: 600;
          }
        }
      }
    }
  }

  // Day and Week views-specific styles
  .fc-timeGridDay-view,
  .fc-timeGridWeek-view,
  .fc-resourceTimeGridDay-view {
    // Today indicator line
    .fc-timegrid-now-indicator-container {
      overflow: visible;

      .fc-timegrid-now-indicator-arrow {
        display: none;
      }
    }

    // Table headers (days of the week)
    .fc-col-header-cell {
      .fc-date {
        display: inline-block;
        width: 28px;
        height: 28px;
        line-height: 28px;
        border-radius: 100%;
      }

      &.fc-day-today {
        color: var(--brand-1-500);

        .fc-date {
          color: var(--white);
          background: var(--brand-1-500);
        }
      }
    }

    // Timegrid slots
    .fc-timegrid-slots,
    .fc-timegrid-axis {
      color: var(--gray-dark-200);
      white-space: nowrap;
    }

    // Timegrid slot labels
    .fc-direction-ltr .fc-timegrid-slot-label-frame {
      text-align: left;
    }

    .fc-timegrid-slot-label {
      font-weight: 400;
      vertical-align: top;
      border: none;
      transform: translate(0, -12px);
    }

    .fc-timegrid-slot-label,
    .fc-timegrid-axis {
      @include body-xs-regular;

      padding-right: 1em;
    }
  }

  // Top-left corner cell
  .fc-timeGridDay-view,
  .fc-timeGridWeek-view {
    .fc-col-header-cell {
      &:nth-child(2) {
        > div {
          border-top-left-radius: 10px;
        }
      }
    }
  }

  .fc-resourceTimeGridDay-view {
    .fc-col-header-cell {
      &:first-child {
        > div {
          border-top-left-radius: 10px;
        }
      }
    }
  }

  // Size variations
  .fc-timeGridDay-view,
  .fc-timeGridWeek-view,
  .fc-resourceTimeGridDay-view {
    .fc-timegrid-slot {
      height: 40px;
    }

    &.small {
      .fc-timegrid-slot {
        height: 24px;
      }
    }

    &.medium {
      .fc-timegrid-slot {
        height: 32px;
      }
    }
  }

  // Month view-specific styles
  .fc-dayGridMonth-view {
    .fc-col-header-cell:first-child {
      > div {
        border-top-left-radius: 10px;
      }
    }

    .fc-scrollgrid-section-body {
      td {
        border-left: 1px solid var(--gray-light-100);
      }
    }

    // Today indicator line
    .fc-daygrid-day-number {
      font-size: 0.875rem;
    }

    .fc-day-today .fc-daygrid-day-number {
      width: 24px;
      height: 24px;
      margin-top: 2px;
      margin-right: 2px;
      padding: 0;
      padding: 2px;
      color: #fff;
      font-weight: 500;
      text-align: center;
      background-color: var(--brand-1-400);
      border-radius: 100%;
    }
  }
}
