@mixin type-text($size: 0.875rem, $weight: 400, $height: 1.43, $spacing: 0) {
  font-weight: $weight;
  font-size: $size;
  font-style: normal;
  line-height: $height;
  letter-spacing: $spacing;
  font-variation-settings: 'wght' $weight;
  font-optical-sizing: auto;
}

/* Heading 1 */
@mixin heading-lg-bold {
  @include type-text(2.5rem, 700, 1.2, -0.1125rem);
}

@mixin heading-lg-semibold {
  @include type-text(2.5rem, 600, 1.2, -0.1125rem);
}

@mixin heading-lg-medium {
  @include type-text(2.5rem, 500, 1.2, -0.1125rem);
}

@mixin heading-lg-regular {
  @include type-text(2.5rem, 400, 1.2, -0.1125rem);
}

@mixin heading-lg-light {
  @include type-text(2.5rem, 300, 1.2, -0.1125rem);
}

/* Heading 2 */
@mixin heading-md-bold {
  @include type-text(2rem, 700, 1.2, -0.1rem);
}

@mixin heading-md-semibold {
  @include type-text(2rem, 600, 1.2, -0.1rem);
}

@mixin heading-md-medium {
  @include type-text(2rem, 500, 1.2, -0.1rem);
}

@mixin heading-md-regular {
  @include type-text(2rem, 400, 1.2, -0.1rem);
}

@mixin heading-md-light {
  @include type-text(2rem, 300, 1.2, -0.1rem);
}

/* Heading 3 */
@mixin heading-sm-bold {
  @include type-text(1.75rem, 700, 1.2, -0.0688rem);
}

@mixin heading-sm-semibold {
  @include type-text(1.75rem, 600, 1.2, -0.0688rem);
}

@mixin heading-sm-medium {
  @include type-text(1.75rem, 500, 1.2, -0.0688rem);
}

@mixin heading-sm-regular {
  @include type-text(1.75rem, 400, 1.2, -0.0688rem);
}

@mixin heading-sm-light {
  @include type-text(1.75rem, 300, 1.2, -0.0688rem);
}

/* Heading 4 */
@mixin heading-xs-bold {
  @include type-text(1.5rem, 700, 1.2, -0.05rem);
}

@mixin heading-xs-semibold {
  @include type-text(1.5rem, 600, 1.2, -0.05rem);
}

@mixin heading-xs-medium {
  @include type-text(1.5rem, 500, 1.2, -0.05rem);
}

@mixin heading-xs-regular {
  @include type-text(1.5rem, 400, 1.2, -0.05rem);
}

@mixin heading-xs-light {
  @include type-text(1.5rem, 300, 1.2, -0.05rem);
}

/* Heading 5 */
@mixin heading-xxs-bold {
  @include type-text(1.25rem, 700, 1.2, -0.0187rem);
}

@mixin heading-xxs-semibold {
  @include type-text(1.25rem, 600, 1.2, -0.0187rem);
}

@mixin heading-xxs-medium {
  @include type-text(1.25rem, 500, 1.2, -0.0187rem);
}

@mixin heading-xxs-regular {
  @include type-text(1.25rem, 400, 1.2, -0.0187rem);
}

@mixin heading-xxs-light {
  @include type-text(1.25rem, 300, 1.2, -0.0187rem);
}

/* alias for Heading 5 */
@mixin subheading-lg-bold {
  @include type-text(1.25rem, 700, 1.2, -0.0187rem);
}

@mixin subheading-lg-semibold {
  @include type-text(1.25rem, 600, 1.2, -0.0187rem);
}

@mixin subheading-lg-medium {
  @include type-text(1.25rem, 500, 1.2, -0.0187rem);
}

@mixin subheading-lg-regular {
  @include type-text(1.25rem, 400, 1.2, -0.0187rem);
}

@mixin subheading-lg-light {
  @include type-text(1.25rem, 300, 1.2, -0.0187rem);
}

/* Heading 6 */
@mixin subheading-md-bold {
  @include type-text(1.125rem, 700, 1.2, -0.0125rem);
}

@mixin subheading-md-semibold {
  @include type-text(1.125rem, 600, 1.2, -0.0125rem);
}

@mixin subheading-md-medium {
  @include type-text(1.125rem, 500, 1.2, -0.0125rem);
}

@mixin subheading-md-regular {
  @include type-text(1.125rem, 400, 1.2, -0.0125rem);
}

/* Paragrah large */

@mixin body-md-bold {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(1rem, 700, 1.5, -0.0063rem);
}

@mixin body-md-semibold {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(1rem, 600, 1.5, -0.0063rem);
}

@mixin body-md-medium {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(1rem, 500, 1.5, -0.0063rem);
}

@mixin body-md-regular {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(1rem, 400, 1.5, -0.0063rem);
}

/* Paragrah small */

@mixin body-sm-bold {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(0.875rem, 700, 1.5, 0);
}

@mixin body-sm-semibold {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(0.875rem, 600, 1.5, 0);
}

@mixin body-sm-medium {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(0.875rem, 500, 1.5, 0);
}

@mixin body-sm-regular {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(0.875rem, 400, 1.5, 0);
}

/** Paragraph Body Small  */
@mixin body-xs-bold {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(0.75rem, 700, 1.5, 0);
}

@mixin body-xs-semibold {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(0.75rem, 600, 1.5, 0);
}

@mixin body-xs-medium {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(0.75rem, 500, 1.5, 0);
}

@mixin body-xs-regular {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(0.75rem, 400, 1.5, 0);
}

/* Caption */
@mixin caption-md-bold {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(0.75rem, 700, 1.2, 0.0125rem);
}

@mixin caption-md-semibold {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(0.75rem, 600, 1.2, 0.0125rem);
}

@mixin caption-md-caps {
  text-transform: uppercase;
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(0.6875rem, 600, 1.2, 0.05rem);
}

@mixin caption-md-medium {
  font-feature-settings:
    'liga' off,
    'clig' off;

  @include type-text(0.75rem, 500, 1.2, 0.0125rem);
}

@mixin caption-sm-bold {
  @include type-text(0.688rem, bold, 1.2, 1px);
}

@mixin caption-sm-semibold {
  @include type-text(0.688rem, 600, 1.2, 0.6px);
}

@mixin caption-sm-medium {
  @include type-text(0.688rem, 500, 1.2);
}

@mixin caption-sm-caps {
  @include type-text(0.688rem, 500, 1.2);

  text-transform: uppercase;
}
