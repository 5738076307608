:root {
  .fc-event {
    background-color: transparent;
    border: none;
    box-shadow: none;
  }

  .fc-h-event div {
    height: auto !important;
  }
}
