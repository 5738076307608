@import './calendar';
@import './events';
@import './now-line';

:root {
  --fc-today-bg-color: transparent;
  --fc-border-color: var(--gray-light-100);

  .fc {
    z-index: 0;
    height: 100%;
  }
}
