.Loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2em 0;
  color: #09254099;
  text-align: center;

  &.fullScreen {
    justify-content: center;
    height: 100vh;
  }
  &.overlay {
    position: fixed;
    backdrop-filter: blur(6px);
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 99999;
  }
}

.LoadingBar {
  position: fixed;
  top: -5px;
  left: 0;
  display: block;
  width: 100%;
  height: 5px;
  background-color: rgb(0 0 0 / 10%);
  opacity: 0;
  transition: opacity 0.3s ease-in-out, top 0.3s ease-in-out;
}

.LoadingBar_Loading {
  top: 0;
  opacity: 1;
}

.LoadingBar_Progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--brand-1-500);
  transition: width 0.3s ease-in-out;
  content: '';
}

.LoadingBar_Progress_Loading {
  animation: progressAnimation 2s;

  // animation-delay: 1s;
  animation-fill-mode: forwards;
}

@keyframes progressAnimation {
  0% {
    width: 20%;
  }

  10% {
    width: 30%;
  }

  70% {
    width: 50%;
  }

  90% {
    width: 90%;
  }

  100% {
    width: 98%;
  }
}
